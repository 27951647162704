import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logout } from '../../actions/auth';
import PeopleIcon from '@material-ui/icons/People';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import CategoryIcon from '@material-ui/icons/Category';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
import BusinessIcon from '@material-ui/icons/Business';
import { getSettingsSync, setSettingHandler } from '../../actions/settings';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    margin: 15,
  },
}));

export default function Menu(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const settings = getSettingsSync();
  const [showTypeRisque, setShowTypeRisque] = React.useState(settings !== null && settings.showTypeRisque);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout()
      .then(() => props.history.push("/"));
  };

  const handlerSettings = settings => {
    setShowTypeRisque(settings !== null && settings.showTypeRisque);
  };

  setSettingHandler(handlerSettings);
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            BVE 33
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button key={"users"} onClick={() => props.history.push("/users")}>
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary={"Utilisateurs"} />
          </ListItem>
          <ListItem button key={"etablissements"} onClick={() => props.history.push("/etablissements")}>
            <ListItemIcon><HomeWorkIcon /></ListItemIcon>
            <ListItemText primary={"Etablissements"} />
          </ListItem>
          <ListItem button key={"risques"} onClick={() => props.history.push("/risques")}>
            <ListItemIcon><WarningIcon /></ListItemIcon>
            <ListItemText primary={"Risques"} />
          </ListItem>

          {
            showTypeRisque ?
              <ListItem button key={"typesRisque"} onClick={() => props.history.push("/typesRisque")}>
                <ListItemIcon><ErrorOutlineIcon /></ListItemIcon>
                <ListItemText primary={"Types de risque"} />
              </ListItem> : null
          }

          <ListItem button key={"categoriesRisque"} onClick={() => props.history.push("/categories-risque")}>
            <ListItemIcon><CategoryIcon /></ListItemIcon>
            <ListItemText primary={"Catégories de risque"} />
          </ListItem>

          <ListItem button key={"typesEtablissement"} onClick={() => props.history.push("/types-etablissement")}>
            <ListItemIcon><BusinessIcon /></ListItemIcon>
            <ListItemText primary={"Types d'établissement"} />
          </ListItem>

          <ListItem button key={"originesRisque"} onClick={() => props.history.push("/originesRisque")}>
            <ListItemIcon><TripOriginIcon /></ListItemIcon>
            <ListItemText primary={"Origines de risque"} />
          </ListItem>

          <ListItem button key={"apropos"} onClick={() => props.history.push("/a-propos")}>
            <ListItemIcon><TripOriginIcon /></ListItemIcon>
            <ListItemText primary={"A propos"} />
          </ListItem>

          <ListItem button key={"settings"} onClick={() => props.history.push("/settings")}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary={"Paramètres"} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key={"disconnect"} onClick={handleLogout}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={"Déconnexion"} />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {
          props.component
        }
      </main>
    </div>
  );
}
